import * as React from 'react';

import { Box, Fade, Flex, Image, Stack, Text } from '@chakra-ui/react';

import ArrowIcon from '../images/ArrowIcon';
import CustomButton from '../components/button';
import IDEImage from '../images/IDEImage';
import IDEAdaptive from '../images/IDEAdaptive';
import Layout from '../components/layout';
import ScrollIcon from '../images/ScrollICon';
import Seo from '../components/seo';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const IndexPage = ({
  data: {
    strapi: {
      homepage: { data },
    },
  },
}) => {
  const breakpoints = useBreakpoint();
  return (
    <Layout>
      <Seo title='Home' />
      <Flex
        flexDirection={{ base: 'column', xl: 'row' }}
        pt={{ base: '14', xl: '20' }}
        justifyContent='center'
        mx={{ base: '4', xl: 'none' }}
      >
        {!breakpoints.xl ? (
          <Flex justifyContent='center'>
            <Flex mb={breakpoints.md ? '20' : '12'}>
              <IDEAdaptive />
            </Flex>
          </Flex>
        ) : null}
        <Flex
          ml={breakpoints.full ? '10.021vw' : '0'}
          flexDirection='column'
          alignItems={{ base: 'center', xl: 'unset' }}
          pt={{ base: '14', xl: '75px' }}
        >
          <Text
            fontFamily='Poppins'
            textTransform='uppercase'
            lineHeight='109%'
            textAlign={{ base: 'center', xl: 'unset' }}
            fontWeight='black'
            letterSpacing='0.03em'
            fontSize={{
              base: '56px',
              xl: breakpoints.laptopL ? '82px' : '62px',
            }}
            color='#303056'
            w={
              breakpoints.laptopL ? '445px' : breakpoints.xl ? '337px' : '100vw'
            }
            h='89px'
          >
            {data.attributes.welcome.title}
          </Text>
          <Text
            fontSize={
              breakpoints.laptopL ? '33px' : breakpoints.xl ? '24px' : '30px'
            }
            w={
              breakpoints.laptopL ? '436px' : breakpoints.xl ? '337px' : '100vw'
            }
            textAlign={{ base: 'center', xl: 'unset' }}
            textTransform='uppercase'
            fontFamily='Poppins'
            lineHeight='109%'
            fontWeight='bold'
            color='#FFFFFF'
            h='36px'
            mt={1.5}
            ml={2.5}
          >
            {data.attributes.welcome.subtitle}
          </Text>
          <Text
            fontFamily='Poppins'
            fontSize='18px'
            lineHeight='180%'
            fontWeight='400'
            color='#303056'
            maxW={
              breakpoints.laptopL ? '445px' : breakpoints.xl ? '300px' : '396px'
            }
            h='129px'
            mt={9}
            ml={2.5}
          >
            {data.attributes.welcome.description}
          </Text>
          <Flex alignItems='center' mt={{ base: '10', xl: '24' }}>
            <CustomButton buttonText={'Our Projects'} />
            {breakpoints.xl ? (
              <>
                <Text
                  fontFamily='Poppins'
                  fontWeight='400'
                  fontSize='15px'
                  lineHeight='22px'
                  color='#303056'
                  ml={breakpoints.laptopL ? '75px' : '4'}
                  w='167px'
                >
                  Over 20 years of coding experience
                </Text>
                <Flex ml='5px'>
                  <ArrowIcon />
                </Flex>
              </>
            ) : null}
          </Flex>
        </Flex>
        <Fade in='true'>
        {breakpoints.xl ? (
          <Box w={840} h={610} ml={breakpoints.xl2 ? '78px' : '-12'} mt='-12'>
              <IDEImage
                width={breakpoints.laptopL ? '1211' : '1150'}
                height={breakpoints.laptopL ? '919' : '945'}
              />

          </Box>
        ) : null}
        </Fade>
      </Flex>
      {breakpoints.xl ? (
        <>
          <Flex ml='55vw'>
            <Flex flexDirection='column' mt='16'>
              <Text
                fontFamily='Poppins'
                fontWeight='bold'
                fontSize='20px'
                lineHeight='180%'
                color='#F8F8FD'
                align='right'
              >
                Full Stack Programming
              </Text>
            </Flex>
            <Flex ml='6' mb='1'>
              <StaticImage
                src='../images/Pin.png'
                placeholder='blurred'
                layout='fixed'
                height={100}
                width={8}
                alt='Pin'
              />
            </Flex>
          </Flex>
          <Flex justifyContent='flex-end' mt='10' mb='8' mr='21vw'>
            <ScrollIcon />
          </Flex>
        </>
      ) : null}
      <Flex
        justifyContent='center'
        alignItems={{ base: 'center', xl: 'unset' }}
        zIndex={10}
        flexDirection={{ base: 'column', xl: 'row' }}
        pt={{ base: '14', xl: '0' }}
        mx={{ base: '4', xl: 'none' }}
      >
        <Box>
          <Image
            alt='service'
            src={`${process.env.GATSBY_DOMAIN_URL}/api${data.attributes.service.illustration.data.attributes.url}`}
            width='600px'
            height='100%'
          />
        </Box>
        <Flex
          flexDirection='column'
          ml={{ base: 'none', xl: '130px' }}
          mt='24'
          alignItems={{ base: 'center', xl: 'unset' }}
        >
          <Text
            fontSize={{ base: '40px', xl: '55px' }}
            mx={{ base: '1', xl: 'none' }}
            textAlign={{ base: 'center', xl: 'unset' }}
            textTransform='uppercase'
            fontFamily='Poppins'
            fontWeight='bold'
            lineHeight='136%'
            color='white'
            maxW='481px'
          >
            {data.attributes.service.title}
          </Text>
          <Box
            borderRadius='10px'
            bgColor='black'
            w='56px'
            h='2px'
            mt='6'
            mb='8'
          />
          <Text
            textAlign={{ base: 'center', xl: 'unset' }}
            fontFamily='Montserrat'
            fontWeight='medium'
            fontSize='18px'
            lineHeight='167%'
            color='white'
            maxW='410px'
            mb='10'
          >
            {data.attributes.service.description}
          </Text>
          <CustomButton buttonText={'Our Contact'} />
        </Flex>
      </Flex>
      <Flex
        justifyContent='center'
        flexDirection='column'
        mx={{ base: '4', xl: 'none' }}
        zIndex={1}
        mt={{ base: '10', xl: '40' }}
        mb={breakpoints.xs ? '0' : '24'}
      >
        {breakpoints.xl ? (
          <Flex position='absolute' alignSelf='center' zIndex={-1} mt='8'>
            <StaticImage
              alt='background'
              placeholder='blurred'
              layout='fixed'
              src='../images/HomepageCircleBG.svg'
            />
          </Flex>
        ) : null}
        <Stack spacing='4' align='center'>
          <Text
            textAlign={{ base: 'center', xl: 'unset' }}
            fontFamily='Poppins'
            letterSpacing='0.25px'
            fontWeight='medium'
            fontSize='19px'
            lineHeight='29px'
            color='rgba(0, 0, 0, 0.6)'
          >
            www.digitalgoals.net
          </Text>
          <Text
            textAlign={{ base: 'center', xl: 'unset' }}
            fontSize={{ base: '64px', xl: '82px' }}
            textTransform='uppercase'
            fontFamily='Poppins'
            letterSpacing='0.03em'
            fontWeight='black'
            lineHeight='109%'
            color='#303056'
          >
            {data.attributes.develop.title}
          </Text>
        </Stack>
        <Flex
          flexDirection={{ base: 'column', xl: 'row' }}
          justifyContent='center'
          mt='8'
        >
          <Flex
            justifyContent='flex-end'
            mr={{ base: breakpoints.xs ? '-4' : '-4', xl: 'unset' }}
          >
            <Flex
              flexDirection='column'
              minW={!breakpoints.xs ? '200px' : '282px'}
            >
              {data.attributes.develop.items
                .map(item => (
                  <Flex
                    mt={{ base: '24', xl: '28' }}
                    maxW='282px'
                    flexDirection='column'
                    key={item.title}
                  >
                    <Flex justifyContent='flex-end' mb='14px'>
                      <Text
                        color='rgba(0, 0, 0, 0.87)'
                        letterSpacing='0.25px'
                        fontFamily='Poppins'
                        fontWeight='medium'
                        lineHeight='29px'
                        textAlign='right'
                        fontSize='19px'
                      >
                        {item.title}
                      </Text>
                      <Image
                        alt={item.icon.data.attributes.alternativeText}
                        src={`${process.env.GATSBY_DOMAIN_URL}/api${item.icon.data.attributes.url}`}
                        alignSelf='center'
                        width='20px'
                        height='20px'
                        ml='4'
                      />
                    </Flex>
                    <Text
                      color='rgba(0, 0, 0, 0.87)'
                      letterSpacing='0.15px'
                      fontFamily='Poppins'
                      fontWeight='normal'
                      textAlign='right'
                      lineHeight='24px'
                      fontSize={!breakpoints.xs ? '13px' : '15px'}
                      minH='96px'
                    >
                      {item.description}
                    </Text>
                  </Flex>
                ))
                .splice(0, 3)}
            </Flex>
            {breakpoints.xl ? null : (
              <Flex zIndex={1} maxW={270}>
                <Image
                  src={`${process.env.GATSBY_DOMAIN_URL}/api${data.attributes.develop.illustration.data.attributes.url}`}
                  objectPosition='5% 0'
                  fit='cover'
                  alt='phone'
                  height='842'
                  width='100%'
                />
              </Flex>
            )}
          </Flex>
          {!breakpoints.xl ? null : (
            <Flex maxH={842} minW={434} zIndex={1} mx='24'>
              <Image
                src={`${process.env.GATSBY_DOMAIN_URL}/api${data.attributes.develop.illustration.data.attributes.url}`}
                position='absolute'
                top='1916'
                alt='phone'
                ml='-45px'
                h='1030px'
                zIndex={-1}
              />
            </Flex>
          )}
          <Flex
            justifyContent='flex-start'
            ml={{ base: breakpoints.xs ? '-4' : '2', xl: 'unset' }}
          >
            {breakpoints.xl ? null : (
              <Flex zIndex={1} ml='-36'>
                <Image
                  src={`${process.env.GATSBY_DOMAIN_URL}/api${data.attributes.develop.illustration.data.attributes.url}`}
                  objectPosition='65% 0'
                  boxShadow={
                    !breakpoints.xs ? '10px 10px 5px rgba(0,0,0,0.01)' : 'unset'
                  }
                  filter='blur'
                  fit='cover'
                  alt='phone'
                  height='842'
                  width='100%'
                />
              </Flex>
            )}
            <Flex
              flexDirection='column'
              minW={!breakpoints.xs ? '200px' : '282px'}
              ml={{
                base: breakpoints.xs ? '-28' : '-24',
                sm: breakpoints.xl ? '0' : '-36',
              }}
            >
              {data.attributes.develop.items
                .map(item => (
                  <Flex
                    mt={{ base: '24', xl: '28' }}
                    maxW='282px'
                    flexDirection='column'
                    key={item.title}
                  >
                    <Flex justifyContent='flex-start' mb='14px'>
                      <Image
                        alt={item.icon.data.attributes.alternativeText}
                        src={`${process.env.GATSBY_DOMAIN_URL}/api${item.icon.data.attributes.url}`}
                        alignSelf='center'
                        width='20px'
                        height='20px'
                        mr='4'
                      />
                      <Text
                        color='rgba(0, 0, 0, 0.87)'
                        letterSpacing='0.25px'
                        fontFamily='Poppins'
                        fontWeight='medium'
                        lineHeight='29px'
                        textAlign='left'
                        fontSize='19px'
                      >
                        {item.title}
                      </Text>
                    </Flex>
                    <Text
                      color='rgba(0, 0, 0, 0.87)'
                      letterSpacing='0.15px'
                      fontFamily='Poppins'
                      fontWeight='normal'
                      textAlign='left'
                      lineHeight='24px'
                      fontSize={!breakpoints.xs ? '13px' : '15px'}
                      minH='96px'
                    >
                      {item.description}
                    </Text>
                  </Flex>
                ))
                .splice(3)}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {breakpoints.xl ? (
        <Flex justifyContent='center' mt='28' mb='36'>
          <CustomButton buttonText={'Our Contact'} />
        </Flex>
      ) : null}
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    strapi {
      homepage {
        data {
          attributes {
            develop {
              title
              illustration {
                data {
                  attributes {
                    url
                  }
                }
              }
              items {
                description
                title
                icon {
                  data {
                    attributes {
                      url
                      alternativeText
                    }
                  }
                }
              }
            }
            welcome {
              description
              subtitle
              title
            }
            service {
              title
              description
              illustration {
                data {
                  attributes {
                    url
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
