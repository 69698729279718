import { Button, Flex, Text } from '@chakra-ui/react';

import { Link } from 'gatsby';
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const CustomButton = ({ buttonText, onClick }) => {
  const renderButton = () => (
    <Flex
      w='169px'
      h='83px'
      justifyContent='center'
      alignItems='center'
      cursor='default'
      outline='none'
      border='none'
    >
      <Button
        outline='none'
        border='none'
        variant='ghost'
        onClick={onClick}
      >
        <StaticImage
          alt='button'
          placeholder='blurred'
          layout='fixed'
          src='../../images/Button.svg'
          style={{ cursor: 'pointer', zIndex: 0, position: 'absolute' }}
        />
        <Text
          fontWeight='semibold'
          fontFamily='Poppins'
          lineHeight='22px'
          cursor='pointer'
          fontSize='15px'
          color='white'
          zIndex={1}
        >
          {buttonText}
        </Text>
      </Button>
    </Flex>
  );

  if (buttonText === 'More') return renderButton();
  else if (buttonText !== 'More')
    return (
      <Link to={buttonText === 'Our Contact' ? '/contact-us' : '/our-projects'}>
        {renderButton()}
      </Link>
    );
};

export default CustomButton;
