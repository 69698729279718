import React from 'react';

function ArrowIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='31'
      height='12'
      fill='none'
      viewBox='0 0 31 12'
    >
      <path stroke='#303056' d='M25 1l5 5m0 0l-5 5m5-5H0'></path>
    </svg>
  );
}

export default ArrowIcon;
